import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import useMedia from 'use-media';
import algoliasearch from 'algoliasearch';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import { dataTransformers } from 'sf-modules';
import { useStateEngine } from '@yema/commerce';
import Section from '@/yema-designkit/webkit/layouts/Section';
import ProductCardSkeleton from '@/yema-designkit/webkit/molecules/ProductCard/ProductCardSkeleton';
import Image from '@/yema-designkit/webkit/atoms/Image';
import Button from '@/yema-designkit/webkit/atoms/Button';
import YDSProductCard from '../products/YDSProductCard';
import { BREAKPOINT_XS } from 'constants/constants';
import CMS_CardSeeMore from './CMS_CardSeeMore';
import Link from '@/yema-designkit/webkit/atoms/Link';

const searchClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
    {
        // Cache responses & promises
        responsesCache: createInMemoryCache(),
        requestsCache: createInMemoryCache({ serializable: false }),
    }
);

const Title = ({ children, isH1, className }) => {
    return isH1 ? (
        <h1 className={className}>{children}</h1>
    ) : (
        <h2 className={className}>{children}</h2>
    );
};

const NoHits = () => {
    return (
        <div className='flex flex-col items-center'>
            <Image
                layout='intrinsic'
                src='https://storage.googleapis.com/yema-assets/icons/no-result-for-product-search.svg'
                alt=''
                width={258}
                height={81}
            />
            <Button href='/s' variants='primary' size='sm' className='mt-3'>
                Ir al catálogo
            </Button>
        </div>
    );
};

function CMSAlgoliaProducts({ content }) {
    const {
        fragment_id,
        background_color,
        title,
        is_h1,
        className,
        content: text,
        columns_mobile,
        columns_desktop,
        hits_per_page,
        query,
        facet_filters,
        numeric_filters,
        card_see_more,
        button,
    } = content;

    const isSmallMobile = useMedia({ maxWidth: BREAKPOINT_XS });
    const [{ catalog }] = useStateEngine();
    const [loading, setLoading] = useState(true);
    const [hits, setHits] = useState([]);

    useEffect(() => {
        if (catalog && catalog.index) {
            /**
             * Search parameters :
             * => query word: index.search('chocolate', { hitsPerPage: 4 })
             * => ailses: index.search('', { hitsPerPage: 4, facetFilters: ["aisles.lvl1:Comida > Botanas"] })
             * => filter on facets: index.search('', { hitsPerPage: 4, facetFilters: ["facets.Dietas:Vegana", "facets.Dietas:Vegetariana"] })
             * => filter on numeric value (price, stock_available): index.search('', { hitsPerPage: 4, numericFilters: ["stock_available>5","stock_available<100"] })
             */
            setLoading(true);
            const index = searchClient.initIndex(catalog.index);
            index
                .search('', {
                    page: 0,
                    hitsPerPage: hits_per_page || 4,
                    query: query || '',
                    numericFilters: numeric_filters
                        ? numeric_filters.split(',').map((x) => x.replace(/['"]+/g, ''))
                        : [],
                    facetFilters: facet_filters
                        ? facet_filters.split(',').map((x) => x.replace(/['"]+/g, ''))
                        : [],
                })
                .then(({ hits: results }) => {
                    setLoading(false);
                    setHits(
                        results.map((product) =>
                            dataTransformers.transformProductFromAlgolia(product)
                        )
                    );
                });
        }
    }, [catalog?.index]);

    return (
        <Section
            id={fragment_id}
            style={{
                backgroundColor: background_color
                    ? background_color
                    : background_color === 'no'
                    ? 'transparent'
                    : '',
            }}
        >
            {title && (
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='mb-6 w-full'>
                            <div className='flex justify-between px-2'>
                                <Title
                                    className={cx(className, 'w-full text-center sm:text-left')}
                                    isH1={is_h1}
                                >
                                    {title}
                                </Title>

                                {button && button.link && button.text && (
                                    <Link
                                        underline={true}
                                        href={button.link}
                                        className='group h-full'
                                    >
                                        {button.text}
                                    </Link>
                                )}
                            </div>
                            <div
                                className='font-body text-normal font-body-regular'
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className='container-fluid m-0 mx-auto'>
                {!loading && !Boolean(hits && hits.length) ? (
                    <NoHits />
                ) : (
                    <div
                        className={cx('grid w-full grid-cols-1 gap-4', {
                            'sm:grid-cols-2': columns_mobile === '2',
                            'lg:grid-cols-2': columns_desktop === '2',
                            'lg:grid-cols-3': columns_desktop === '3',
                            'lg:grid-cols-3 xl:grid-cols-4': columns_desktop === '4',
                        })}
                    >
                        {loading
                            ? [...Array(hits_per_page).keys()].map((x) => (
                                  <ProductCardSkeleton key={x} />
                              ))
                            : hits?.map((item) => (
                                  <YDSProductCard
                                      key={item.sku}
                                      product={item}
                                      row={isSmallMobile}
                                      showReviews={true}
                                  />
                              ))}

                        {!loading && card_see_more && card_see_more.link && (
                            <CMS_CardSeeMore content={card_see_more} />
                        )}
                    </div>
                )}
            </div>
        </Section>
    );
}

CMSAlgoliaProducts.defaultProps = {};

CMSAlgoliaProducts.propTypes = {
    content: PropTypes.object.isRequired,
};

export default React.memo(CMSAlgoliaProducts);
