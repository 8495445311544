import React from 'react';
import cx from 'classnames';
import { ArrowRightIcon } from 'icons/solid';
import Link from '@/yema-designkit/webkit/atoms/Link';

function CMS_CardSeeMore({ content }) {
    const { link, button, background_color, text_color, text } = content;
    const cardLink = link || button?.link;

    return (
        <Link underline={false} href={cardLink} className='group h-full'>
            <div
                className={cx(
                    'h-full w-full py-4',
                    'flex flex-col items-center justify-center gap-y-2',
                    'shadow-down group-hover:shadow-down lg:shadow-none'
                )}
                style={{
                    backgroundColor: background_color,
                    color: text_color,
                }}
            >
                {content.content && (
                    <div
                        className='mb-2 text-center'
                        dangerouslySetInnerHTML={{ __html: content.content }}
                    />
                )}
                <div
                    className={cx(
                        'h-10 w-10 rounded-full bg-brand-primary-700 lg:h-12 lg:w-12',
                        'flex items-center justify-center',
                        'duration-300 ease-in group-hover:translate-x-1'
                    )}
                >
                    <ArrowRightIcon className='h-6 text-ui-white lg:h-8' />
                </div>
                {text}
            </div>
        </Link>
    );
}

export default CMS_CardSeeMore;
